@use 'styles/config' as *;
@use "sass:math";

.hero {
  position: relative;
  margin-top: -$layout-padding-top-mobile;
  margin-bottom: 3rem;
  padding-top: $layout-padding-top-mobile;
  // padding-bottom: $layout-padding-bottom-mobile;
  height: 100vh;
  // min-height: 95rem;

  // color: $color-font;
  // background: #fff;

  color: #fff;
  background: $color-font;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 25rem;
    z-index: 1;

    background: linear-gradient(0deg, rgba(#000, 0), rgba(#000, 0.6));
    mix-blend-mode: darken;
  }

  @media (min-width: $min-720) {
    color: #fff;
    background: $color-font;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 50%;
      z-index: 1;

      background: linear-gradient(90deg, rgba(#000, 0.6), rgba(#000, 0.4), rgba(#000, 0));
      mix-blend-mode: darken;
    }
  }

  @include nav-offset-padding();

  @media (min-width: $min-720) {
    margin-top: -$layout-padding-top;
    margin-bottom: 5rem;
    padding-top: $layout-padding-top;
    padding-bottom: $layout-padding-bottom;

    min-height: 95rem;
  }

  &__container {
    @include container();

    display: flex;
    align-items: flex-end;

    position: relative;
    z-index: 2;

    height: 100%;

    @media (min-width: $min-720) {
      align-items: center;
    }
  }

  &__row {
    @include grid-row;
  }

  &__content {
    @include grid-col();
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
    width: 100%;
    // width: clamp(32rem, 100%, 60rem);

    // @media (min-width: $min-720) {
    //   @include grid-col(7);
    // }

    // @media (min-width: $min-960) {
    //   @include grid-col(7);
    // }
  }

  &__tagline {
    display: flex;
    align-items: center;

    margin: 0 0 4rem;

    &Index {
      @include tagline;

      margin-right: 2rem;
    }

    &Text {
      @include taglineWithLine;
    }
  }

  &__title {
    @include h1(2.3rem, 7.2rem);
    line-height: 1.1;
    // font-size: responsive(2.8rem, 4.8rem);
    text-wrap: balance;
    max-width: 24ch;

    @media (max-width: $max-720) {
      text-transform: uppercase;
      line-height: 1.3;
    }
  }

  &__text {
    margin-top: 2.5rem;

    font-size: responsive(1.6rem, 2.4rem);
    line-height: 1.5;
    letter-spacing: math.div(0.58, 14) * 1em;
    max-width: 43ch;

    a {
      @include anchor();
    }

    p {
      @include reset-paragraph;

      &:not(:last-child) {
        margin: 0 0 2rem;
      }
    }

    @media (min-width: $min-720) {
      margin-top: 3.5rem;
      // margin-bottom: 12.5rem;
    }
  }

  &__button {
    margin-top: 4rem;

    @media (min-width: $min-720) {
      margin-top: 6rem;
    }
  }

  &__scrollwrap {
    position: absolute;
    bottom: 0;
    display: none;

    @media (min-width: $min-720) {
      display: block;
      align-self: flex-end;
    }
  }

  &__scroller {
    position: relative;
    padding-left: 2rem;
    padding-bottom: 5.5rem;

    &::after {
      content: '';
      position: absolute;
      inset: -15rem -14rem -6rem;
      z-index: -1;
      background: radial-gradient(closest-side, rgba(#000, 0.2) 0%, rgba(#000, 0) 100%);
    }

    @media (min-width: $min-600) {
      position: absolute;
      padding-left: 0;

      bottom: 0;
    }

    @include no-supports-grid() {
      left: $container-gutter-desktop;
    }
  }

  &__background {
    position: absolute 0;
    z-index: 0;

    &::before,
    &::after {
      display: none;

      @media (min-width: $min-720) {
        display: block;
      }
    }

    &::before {
      content: '';
      position: absolute 0;
      right: 50%;
      z-index: 3;

      // background: #000;
      background: linear-gradient(270deg, rgba(#000, 0), rgba(#000, 0.4));
      mix-blend-mode: darken;

      @media (max-width: $max-720) {
        display: block;

        right: 0;
        top: 25%;

        background: linear-gradient(180deg, rgba(#000, 0), rgba(#000, 0.7));
      }
    }

    &::after {
      content: '';
      position: absolute 0;
      left: auto;
      width: 40rem;
      z-index: 3;

      // background: #000;
      // background: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.5));
    }
  }

  &__image {
    position: absolute 0;

    background: center no-repeat;
    background-size: cover;
    background-position: center 0;

    .hasPortrait & {
      display: none;

      @media (min-width: $min-720) {
        display: block;
      }
    }

    &Portrait {
      position: absolute 0;

      background: center no-repeat;
      background-size: cover;
      background-position: center 0;

      @media (min-width: $min-720) {
        display: none;
      }
    }
  }

  &__video {
    // position: relative;
    z-index: 1;
    // width: 100%;
    // height: 100%;

    // object-fit: cover;

    // // opacity: 0;
    // transition: opacity 500ms;

    // &.ready {
    //   opacity: 1;
    // }
  }

  &__ticker {
    @include containerNoLimit;
    display: none;
    position: absolute;
    right: 0;
    bottom: 8rem;

    @media (min-width: $min-960) {
      display: block;
    }
  }
}
